<template>
    <div>
        <MenuStyle2 />
        <PageHeader pageTitle="Software HMS per ETS e Strutture Ricettive" parentPage="Home" />
        <HMSSoftware />
        <Footer />
    </div>
</template>

<script>

import MenuStyle2 from '@/components/layout/MenuStyle2'
import PageHeader from '@/components/layout/PageHeader'
import HMSSoftware from '@/components/page_components/home/home_1/HMSSoftware'
import Footer from '@/components/layout/Footer'

export default {
    name: "Contact",
    components: {
        MenuStyle2,
        PageHeader,
        HMSSoftware,

        Footer
    }
};

</script>

<style scoped></style>
